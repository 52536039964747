import * as React from "react"

type Props = {
  text: string;
  link: string;
}

export const Button = (props: Props): React.ReactElement => {
  return (
    <a href={props.link}>
      <button type="button" className="btn btn-white btn-lg px-4 me-md-2 me-2 mb-2">{props.text}</button>
    </a>
  );
}

export default Button;

export const NavigationButton = (props: Props): React.ReactElement => {
  return (
    <a href={props.link}>
      <button type="button" className="btn btn-blue btn-lg">{props.text}</button>
    </a>
  );
}

export const ButtonSecondary = (props: Props): React.ReactElement => {
  return (
    <a href={props.link}>
      <button type="button" className="btn btn-secondary btn-lg px-4 me-2 mb-2">{props.text}</button>
    </a>
  );
}

export const ButtonWhite = (props: Props): React.ReactElement => {
  return (
    <a href={props.link}>
      <button type="button" className="btn btn-primary btn-lg px-4 me-2 mb-2">{props.text}</button>
    </a>
  );
}

export const ButtonOutline = (props: Props): React.ReactElement => {
  return (
    <a href={props.link}>
      <button type="button" className="btn btn-outline-white btn-lg px-4 me-2 mb-2">{props.text}</button>
    </a>
  );
}