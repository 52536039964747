import * as React from "react"

import { Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";

import '../i18n/config';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSecondary } from "../components/button";
import { Helmet } from "react-helmet";
import HeroImage from '../images/stock/FoundryProcess.jpeg';
import CTAImage from '../images/stock/FoundryControl.jpeg';

import Modal from 'react-bootstrap/Modal';

function Home() {
  const { t } = useTranslation();
  const [imageOpen, setImageOpen] = React.useState<boolean>(false);
  const handleClose = () => setImageOpen(false);
  const handleShow = () => setImageOpen(true);

  useEffect(() => {
    document.title = 'RedNet Systems - Foundry Software';
  }, []);

  return (
    <main>
      <Helmet>
        <title>RedNet Systems - Homepage</title>
        <meta name="description" content="RedNet Systems supply software solutions, customised to manage the important processes within your business." />
      </Helmet>

      {/* Hero */}
      <div
        className='text-center hero-container' id='home'
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='mask py-8 d-flex justify-content-center align-items-center'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white px-4 px-md-8 px-lg-10'>
              <h1 className='mb-5'>{t('homepage.hero.title')}</h1>
              <h2 className='heroSubheader mb-5'>{t('homepage.hero.heading')}</h2>
              <Button link="/contact" text={t('homepage.callToAction.buttonText')} />
            </div>
          </div>
        </div>
      </div>

      <Container className="py-8">
        {/* Video */}
        {/* <Row className="py-8 text-center">
          <h2 className="pb-2">{t('homepage.solution.heading')}</h2>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe width="720px" height="480px" title="Embeds Page" className="embed-responsive-item" src="https://www.youtube.com/embed/rqgmVvtP944"></iframe>
          </div>
        </Row> */}

        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col-md-7">

            <hr />
            <h2>{t('homepage.problem.heading')}</h2>

            <p>{t('homepage.problem.text1')}</p>
            <p>{t('homepage.problem.text2')}</p>
            <p>{t('homepage.problem.text3')}</p>
            <p>{t('homepage.problem.text4')}</p>

          </div>
          <div className="col-md-5">
            <img onClick={handleShow} className="img-fluid mw-md-150 mw-lg-130" src={require('../images/software/ScrapGraph.jpg')} alt="Graph showing scrap with reason code" />
          </div>
        </div>

        <Modal show={imageOpen} onHide={handleClose} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Graph showing reasons for scrap</Modal.Title>
          </Modal.Header>
          <img onClick={handleShow} className="img-fluid" src={require('../images/software/Large-Scrap-Graph-BI.jpg')} alt="Graph showing scrap with reason code" />
          <Modal.Footer>
            <a className="btn btn-primary text-white" onClick={handleClose}>
              Close
            </a>
          </Modal.Footer>
        </Modal>

        <div className="row featurette align-items-center py-6 py-sm-8" id='about'>
          <div className="col-md-7 order-md-2">
            <hr />
            <h2>{t('homepage.about.heading')}</h2>
            <p>{t('homepage.about.text1')}</p>
            <p>{t('homepage.about.text2')}</p>
            <p>{t('homepage.about.text3')}</p>
          </div>
          <div className="col-md-5 order-md-1">
            <img className="img-fluid mw-md-150 mw-lg-130" src={require('../images/DavidWorkman.jpg')} alt="David Workman" />
          </div>
        </div>

        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col-md-7">
            <hr />
            <h2>{t('homepage.benefits.heading')}</h2>
            <p>{t('homepage.benefits.benefit1')}</p>
            <p>{t('homepage.benefits.benefit2')}</p>
          </div>
          <div className="col-md-5">
            <img className="img-fluid mw-md-150 mw-lg-130" src={require('../images/stock/DieCast.jpeg')} alt="Software for die casting management" />
          </div>
        </div>

        {/* Testimonials */}

        <Container className="py-6 px-md-5 px-8" id='testimonials'>
          <h2 className="text-center pb-2">{t('homepage.testimonials.heading')}</h2>

          <p className="lead">"I worked in the foundry industry for 45 years and as Quality Manager at Vanguard Foundry for 17 years. We used Data Manager software to record and retrieve quality and production control data on a daily basis. The software was a fundamental part of my role and we relied on it for monitoring and reporting quality issues. It fully supported our ISO 9002 certification. During product audits we were able to show full traceability, historical information and continual improvement. We used the system to store photographs of tooling, quality issues, corrective actions and care points."</p>
          <p> - Mr. Martin Jones. Ex-Quality Manager. Vanguard Foundry Ltd.</p>

          <div className="text-center">
            <ButtonSecondary text="Read More" link="/testimonials" />
          </div>
        </Container>

        {/* Call to action */}
      </Container>
      <div
        className='text-center hero-container'
        style={{
          backgroundImage: `url(${CTAImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='mask py-8 d-flex justify-content-center align-items-center' style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          minHeight: '600px',
          margin: 0,
        }}>
          <Row className='d-flex align-items-center h-100'>
            {/* <Col className="d-none d-md-block">
              <img className="img-fluid mw-md-150 mw-lg-130" src={require('../images/software/MonitorView.png')} alt="Clinical waste bag" />
            </Col> */}
            <Col className='text-white pe-4'>
              <h1 className='mb-5'>{t('homepage.callToAction.heading')}</h1>
              <Button text="Contact Us" link="/contact" />
            </Col>
          </Row>
        </div>
      </div>

    </main>
  );
}

export default Home;