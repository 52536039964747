import React, { useLayoutEffect } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, Link, useLocation, Navigate } from "react-router-dom";
import { Telephone } from "react-bootstrap-icons"
import { Container, Nav, Navbar } from "react-bootstrap";

import ContactPage from './pages/contact';
import TestimonialPage from './pages/testimonials';
import HomePage from './pages/home';
import Footer from './sections/footer';

function App() {
  const ScrollToTopWrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  return (
    <BrowserRouter>
      <ScrollToTopWrapper>
        <Navbar collapseOnSelect id="mainNav" className="site-header sticky-top text-white" expand="md" bg="light" variant="dark">
          <Container>
            <Navbar.Brand id="branding" href="/"><img className="my-auto" src={require('./images/RedNetSystemsLogo.png')} alt="RedNet Systems Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto mb-4 mb-xl-0">
                <Nav.Link as={Link} to='/' eventKey="0">Home</Nav.Link>
                <Nav.Link as={Link} to='/testimonials' eventKey="0">Testimonials</Nav.Link>
                <Nav.Link as={Link} to='/contact' eventKey="0">Contact</Nav.Link>
              </Nav>
                <div className="d-flex">
                  <a className="navbar-phone text-white text-decoration-none" href="tel:+44 1905 612637"><Telephone color="white" size={24} className="text-end mx-3" /><strong>+44 (0) 1905 612637</strong></a>
                </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/testimonials" element={<TestimonialPage />} />

          {/* Redirects from old site */}
          <Route path="/contact-us" element={<Navigate replace to="/contact" />} />
          
          <Route path="/Data_Manager_Foundry_Software" element={<Navigate replace to="/" />} />

        </Routes>
        <Footer />
      </ScrollToTopWrapper>
    </BrowserRouter>
  );
}

export default App;
