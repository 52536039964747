import * as React from "react"
import { Facebook, Linkedin } from "react-bootstrap-icons"

export default function Footer() {
  return (
    <footer className="pb-2 pt-6 bg-tertiary text-white text-center">
      <a href="https://www.linkedin.com/in/david-workman-81803937/" target="_blank" rel="noreferrer" className="text-white text-decoration-none me-5">
        <div className="d-inline p-2">
        <Linkedin color="#0077B5" size={40} className="me-2" /></div>Connect with David Workman on LinkedIn
      </a>

      <p className="text-center text-light mt-4">Website designed by <a className="text-white" href="https://www.webhq.uk" target="_blank" rel="noopener noreferrer">WebHQ</a></p>
    </footer>
  )
}
