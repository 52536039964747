import { Container } from "react-bootstrap";
import { useEffect } from "react";
import HeroImage from '../images/stock/FoundryControl.jpeg';
import { Helmet } from "react-helmet";
import { Telephone, Mailbox } from "react-bootstrap-icons";

function Contact() {
  useEffect(() => {
    document.title = 'Contact RedNet Systems';
  }, []);

  return (
    <main>
      <Helmet>
        <title>RedNet Systems - Homepage</title>
        <meta name="description" content="RedNet Systems supply software solutions, customised to manage the important processes within your business." />
      </Helmet>

      {/* Hero */}
      <div
        className='text-center hero-container'
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='mask py-4 d-flex justify-content-center align-items-center'>
          <div className='d-flex justify-content-center align-items-center h-50'>
            <div className='text-white px-2 px-md-4 px-lg-4'>
              <h1 className='mb-2'>Contact RedNet Systems</h1>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <div className="row featurette align-items-center py-6 py-sm-8">
          <div className="col-md-7 order-md-2 pb-6">
            <hr />
            <h2>Email or Call</h2>
            <a className="navbar-phone text-decoration-none d-block mb-4" href="tel:+44 1905 612637"><Telephone color="red" size={24} className="text-end mx-3" /><strong>+44 (0) 1905 612637</strong></a>
            <a className="navbar-phone text-decoration-none" href="mailto:david.workman@rednetsystems.co.uk"><Mailbox color="red" size={24} className="text-end mx-3" /><strong>david.workman@rednetsystems.co.uk</strong></a>
          </div>
          <div className="col-md-5 order-md-1">
            <img className="img-fluid mw-md-150 mw-lg-130" src={require('../images/DavidWorkman.jpg')} alt="David Workman" />
          </div>
        </div>
      </Container>
    </main>
  );
}

export default Contact;