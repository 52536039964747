import { Container } from "react-bootstrap";
import { useEffect } from "react";
import HeroImage from '../images/stock/FoundryControl.jpeg';
import { Helmet } from "react-helmet";
import { Telephone, Mailbox } from "react-bootstrap-icons";

function Testimonials() {

  const testimonials = [
    {
      id: 1,
      content:
        "Having been part of the foundry industry, with positions at numerous companies for over 35 years, I have had the experience of a number of ERP systems. Data Manager always stood out as being user friendly, and through the collaborative nature of David Workman completely tailored to the company and individual user’s needs. This always made the planning and quality data easy to interrogate enabling us to make informed decisions. As with all IT systems technical support is imperative and David was always there to support our needs and advise where necessary.",
      author: "Lee Shepherd Ex-Managing Director, Vanguard Foundry Ltd"
    },
    {
      id: 2,
      content:
        "Data Manager has given us improved control in all areas of foundry administration from enquiries through to order handling, production control and despatch. RedNet Systems offers a hands on approach and is always prepared to help us implement our evolving requirements.",
      author: "David Ball, Q. A. Manager, Quality Castings (Slough) Ltd"
    },
    {
      id: 3,
      content:
        "Data Manager has become an integral part of almost all of our business systems. It has allowed us to keep administration costs under control as our company has changed and developed over the last 10 years. We manage our business using the information generated by this system.",
      author: "Linda Carter, Systems Manager, Maycast-Nokes Precision Engineering Ltd"
    },
    {
      id: 4,
      content:
        "Bonds Foundry had been using the Dewtec production system since 1996. When the time came in 2009 to upgrade to a Windows based system a number of alternatives were evaluated, with Data Manager being selected as the most suited to our requirements.  In 2010 Data Manger was successfully installed over the course of 1 weekend. The transfer in of historical data and the implementation of the system was seamless without any disruption to production. Data Manager has fulfilled all our business needs, is easy to use and the level of support has been excellent. So much so that when it came to selecting a system for our new foundry, Bonds Cast-Tech in 2012, Data Manager was the first choice.",
      author: "David March. Quality Manager. Bonds Cast-Tech Ltd"
    },
    {
      id: 5,
      content:
        "I worked in the foundry industry for 45 years and as Quality Manager at Vanguard Foundry for 17 years. We used Data Manager software to record and retrieve quality and production control data on a daily basis. The software was a fundamental part of my role and we relied on it for monitoring and reporting quality issues. It fully supported our ISO 9002 certification. During product audits we were able to show full traceability, historical information and continual improvement. We used the system to store photographs of tooling, quality issues, corrective actions and care points.",
      author: "Mr. Martin Jones. Ex-Quality Manager. Vanguard Foundry Ltd."
    }
  ];

  return (
    <main>
      <Helmet>
        <title>RedNet Systems - Testimonials</title>
        <meta name="description" content="RedNet Systems supply software solutions, customised to manage the important processes within your business." />
      </Helmet>

      {/* Hero */}
      <div
        className='text-center hero-container'
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='mask py-4 d-flex justify-content-center align-items-center'>
          <div className='d-flex justify-content-center align-items-center h-50'>
            <div className='text-white px-2 px-md-4 px-lg-4'>
              <h1 className='mb-2'>Our Testimonials</h1>
            </div>
          </div>
        </div>
      </div>

      <Container className="my-8">
        {testimonials.map((c, index) => {
          return (
            <blockquote className="blockquote mb-8" >
              <p>
                "{c.content}"
              </p>
              <footer className="blockquote-footer">
                {c.author}
              </footer>
            </blockquote>
          );
        })}


      </Container>
    </main >
  );
}

export default Testimonials;